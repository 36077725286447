// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/nette.ajax.history'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}


import { android, iOS, IE, CMS } from './core/config'

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import ScrollService from './services/Scroll'

if ( IE || android || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
    ScrollService.useNative()
    setScrollPosition()
} else {
    ScrollService.useVirtual()
        .catch(err => {
            ScrollService.useNative()
            console.log(err)
        })
        .finally(() => setScrollPosition())
}

// ScrollService.useNative()

import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()
// loadComponents().then(() => {
//     const id = window.location.hash.replace('#', '')
//     const anchor = document.getElementById(id)
//     if (anchor) {

//         setTimeout(() => {
//             let offset = anchor.matches('.Section:not(.Section--appended):not(.Section--bare)') ? 0 : 40
//             offset = anchor.getBoundingClientRect().top + ScrollService.getScroll().y - offset
//             ScrollService.setPosition(0, offset)
//         }, 100)
//     }
// })


/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'
document.addEventListener("lazybeforeunveil", function (e) {
    var bg = e.target.getAttribute("data-bg")
    if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")"
    }
})



function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'top' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}


import Swup from 'swup'
// import SwupGtmPlugin from '@swup/gtm-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupJsPlugin from '@swup/js-plugin'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'
import {EVENTS} from './core/Events'
import Component from './core/Component'

if (!CMS) {
    const jsPluginOptions = [
        {
            from: '(.*)',
            to: '(.*)',
            in: next => next(),
            out: next => next()
        },
        {
            from: '(.*)',
            to: '(.*)',
            in: next => {
                document.documentElement.classList.remove('is-circle-animating')
                const circleEl = document.querySelector('.Circle')

                const circle = Component.getFromElement(circleEl)
                if (circle) {
                    circle.animateIn().then(next)
                } else {
                    next()
                }
            },
            out: next => {
                const circleEl = document.querySelector('.Circle')

                const circle = Component.getFromElement(circleEl)
                if (circle) {
                    document.documentElement.classList.add('is-circle-animating')
                    circle.animateOut().then(next)
                } else {
                    next()
                }
            }
        }
    ]

    const swup = new Swup({
        linkSelector: [
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="/"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="#"]:not([data-no-swup]):not([data-component]):not(.ajax)'
        ].join(', '),
        // animationSelector: '',
        plugins: [new SwupBodyClassPlugin(), new SwupJsPlugin(jsPluginOptions)], //, new SwupGtmPlugin()
        containers: ['#swup', '#header-linksinner', '#popupnav-menu', '#footer'],
        skipPopStateHandling: event => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        }
    })

    swup.on('clickLink', event => {
        EventBus.emit(EVENTS.SWUP_CLICK_LINK, { event })
    })

    swup.on('willReplaceContent', event => {
        EventBus.emit(EVENTS.SWUP_WILL_REPLACE_CONTENT)

        queryAll('[data-swup]').forEach(element => {
            removeComponents(element);
        })
    })

    swup.on('contentReplaced', event => {
        Nette.reinit()

        setScrollPosition(0,0)

        queryAll('[data-swup]').forEach(element => {
            loadComponents(element);
        })

        if ($.nette) {
            $.nette.load()
        }

        setTimeout(() => ScrollService.resize(), 0)
        EventBus.emit(EVENTS.SWUP_CONTENT_REPLACED)
    })
}

// var oldEPD = Event.prototype.preventDefault;
// Event.prototype.preventDefault = function() {
//     debugger;
//     oldEPD.call(this);
// };
