export const EVENTS = {
    DOM_CHANGE: 'dom:change',
    SWUP_CLICK_LINK: 'swup:clicklink',
    SWUP_WILL_REPLACE_CONTENT: 'swup:willreplacecontent',
    SWUP_CONTENT_REPLACED: 'swup:contentreplaced',

    POPUPNAV_CLOSE: 'popupnav:close',
    POPUPNAV_OPEN: 'popupnav:open',

    SECTIONOBSERVER_SECTIONCHANGE: 'sectionobserver:sectionchange'

}